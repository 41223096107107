import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import * as styles from "./index.module.less";

const Banner = () => {
  return (
    <Center
      w="full"
      // p={{
      //   base: "48px 24px 40px",
      //   sm: "64px 40px 40px",
      //   md: "64px 40px 48px",
      //   lg: "80px 40px 48px",
      //   "2xl": "120px 80px 64px",
      // }}
      bg="#F8F9FF"
    >
      <Center
        maxW="web_max_w"
        flexDir="column"
        textAlign="center"
        gap={{ base: "12px", lg: "24px" }}
      >
        <Text as="h1" my="0">
          Manage Meetings with Ease.{" "}
          <Text
            as="span"
            bg="linear-gradient(90.22deg, #E862CA 76.13%, #9F2AEC 102.07%)"
            bgClip="text"
          >
            Start free.
          </Text>
        </Text>
        <Text style={{ margin: "0" }}>
          Choose a plan that fits your needs, with the flexibility to upgrade or
          downgrade anytime.
        </Text>
      </Center>
    </Center>
  );
};
export default Banner;
