import BlokContainer from "@/components/blok-container";
import CommonButton from "@/components/common-button";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Center, Text, Image, Box, Flex } from "@chakra-ui/react";
import React from "react";
import * as styles from "./index.module.less";

const BottomCta = () => {
  return (
    <BlokContainer maxW="web_max_w">
      <Center
        bg="#FCF4FF"
        w="full"
        p={{
          base: "24px 16px",
          sm: "32px 16px",
          md: "32px 64px",
          lg: "48px 24px",
          xl: "32px 48px",
          "2xl": "48px 64px",
        }}
        borderRadius={{
          base: "24px",
          sm: "40px",
        }}
        gap={{
          base: "32px",
          xl: "64px",
        }}
        justifyContent="space-between"
        flexDir={{
          base: "column-reverse",
          lg: "row",
        }}
      >
        <Image
          src={LOADING_IMAGE_URL}
          w={{
            base: "280px",
            sm: "300px",
            md: "320px",
            lg: "280px",
            "2xl": "360px",
          }}
          h={{
            base: "210px",
            sm: "225px",
            md: "240px",
            lg: "210px",
            "2xl": "270px",
          }}
          borderRadius="12px"
          className="lozad"
          data-src="https://a.storyblok.com/f/167495/360x270/53fdab485f/.svg"
          alt="educational person"
          data-object-fit="contain"
        />
        <Flex
          flexDir="column"
          gap={{ base: "16px", sm: "24px" }}
          alignItems={{
            base: "center",
            lg: "flex-start",
          }}
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          <Text my="0" as="h2">
            50% off for nonprofits and educators
          </Text>
          <Text style={{ margin: "0" }}>
            Your impact is important. When we are aware that Airgram can help
            you record sessions and better takes notes for meetings, we are
            excited to land a hand.
          </Text>
          <CommonButton
            to="https://tally.so/r/w2ELRe"
            content="Apply"
            _hover={{
              bg: "#7C1AC7",
            }}
            _active={{
              bg: "#5C0DA1",
            }}
          />
        </Flex>
      </Center>
    </BlokContainer>
  );
};
export default BottomCta;
