import React from "react";
import * as styles from "./index.module.less";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { AddOnData } from "../constants";

const AddOn = () => {
  const { tagList, title, desc, featureList } = AddOnData;
  return (
    <Flex
      w="full"
      maxW={{
        base: "460px",
        lg: "944px",
        "2xl": "1008px",
      }}
      p={{
        base: "24px",
      }}
      borderRadius={{
        base: "16px",
      }}
      border="1px solid #F3D1FF"
      flexDirection="column"
      gap="16px"
      className={styles.container}
    >
      <Flex gap="8px">
        {tagList.map((item) => {
          return (
            <Center
              borderRadius="4px"
              bg="#F3D1FF"
              key={item}
              fontSize={{
                base: "12px",
                lg: "14px",
              }}
              lineHeight={{
                base: "18px",
                lg: "20px",
              }}
              fontWeight="600"
              p="0 6px"
              color="#9F2AEC"
            >
              {item}
            </Center>
          );
        })}
      </Flex>
      <Flex
        alignItems={{
          base: "flex-start",
          lg: "center",
        }}
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <Flex
          flexDir="column"
          gap={{
            base: "6px",
            md: "8px",
          }}
          flexBasis={{
            base: "auto",
            lg: "522px",
            xl: "560px",
          }}
          pr={{
            base: "0",
            lg: "24px",
          }}
          pb={{
            base: "16px",
            lg: "0px",
          }}
          borderBottom={{
            base: "1px solid #F3D1FF",
            lg: "none",
          }}
          borderRight={{
            base: "none",
            lg: "1px solid #F3D1FF",
          }}
        >
          <Text as="h3">{title}</Text>
          <Text>{desc}</Text>
        </Flex>
        <Flex
          flexDir="column"
          flexBasis={{
            base: "auto",
            lg: "326px",
          }}
          pl={{
            base: "0px",
            lg: "24px",
          }}
          pt={{
            base: "16px",
            lg: "0px",
          }}
          gap={{
            base: "12px",
            lg: "16px",
          }}
        >
          {featureList.map((item) => {
            return (
              <Flex alignItems="center" key={item.name} gap="8px">
                <Image
                  src={item.icon.src}
                  alt={item.icon.alt}
                  w={{
                    base: "18px",
                    lg: "20px",
                  }}
                  h={{
                    base: "18px",
                    lg: "20px",
                  }}
                  objectFit="contain"
                  flexShrink="0"
                />
                <Box flex={1}>
                  <Text as="h5" display="inline">
                    {item.name}
                  </Text>
                  {item.tips && (
                    <Text color="#7D848E" display="inline" ml="8px">
                      {item.tips}
                    </Text>
                  )}
                </Box>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default AddOn;
