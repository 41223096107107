import BlokContainer from "@/components/blok-container";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Center, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { SECURITY_LOGOS } from "../constants";
import * as styles from "./index.module.less";

const SecurityLogos = () => {
  return (
    <BlokContainer
      maxW="web_max_w"
      pt={{
        base: "64px",
        sm: "80px",
        lg: "96px",
        xl: "120px",
      }}
      pb="0px"
    >
      <Center gap={{ base: "24px", md: "48px" }} flexDir="column">
        <Flex gap={{ base: "12px", sm: "24px", lg: "48px" }}>
          {/* 展示位置不一样，上面两个logo下面4个logo */}
          {SECURITY_LOGOS.slice(0, 2).map((item) => {
            return (
              <Image
                key={item.alt}
                className="lozad"
                src={LOADING_IMAGE_URL}
                data-src={item.src}
                data-object-fit="contain"
                objectFit="cover"
                borderRadius="100%"
                alt={item.alt}
                w={{
                  base: "48px",
                  sm: "62px",
                  md: "80px",
                  lg: "100px",
                }}
                h={{
                  base: "48px",
                  sm: "62px",
                  md: "80px",
                  lg: "100px",
                }}
              />
            );
          })}
        </Flex>
        <Flex
          gap={{ base: "8px", sm: "24px", lg: "48px" }}
          flexWrap="wrap"
          justifyContent="center"
        >
          {SECURITY_LOGOS.slice(2).map((item) => {
            return (
              <Image
                key={item.alt}
                className="lozad"
                src={LOADING_IMAGE_URL}
                data-src={item.src}
                data-object-fit="contain"
                objectFit="cover"
                alt={item.alt}
                w={{
                  base: "46px",
                  sm: "62px",
                  md: "80px",
                  lg: "100px",
                }}
                h={{
                  base: "54px",
                  sm: "78px",
                  md: "100px",
                  lg: "130px",
                }}
              />
            );
          })}
        </Flex>
      </Center>
    </BlokContainer>
  );
};
export default SecurityLogos;
