import BlokContainer from "@/components/blok-container";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IUserComment, USER_COMMENTS } from "../constants";
import * as styles from "./index.module.less";

interface ICommentCard {
  data: IUserComment;
}

const CommentCard = ({ data }: ICommentCard) => {
  return (
    <Flex
      w="full"
      h="full"
      borderRadius="16px"
      boxShadow="0px 4px 25px rgba(0, 0, 0, 0.1)"
      p={{
        base: "24px",
        sm: "32px",
        md: "48px",
      }}
      flexDir="column"
      gap="24px"
      bg="#fff"
    >
      <Flex
        gap="16px"
        alignItems="center"
        flexDir={{ base: "column", lg: "row" }}
      >
        <Image
          data-src={data.avatar.src}
          src={LOADING_IMAGE_URL}
          alt={data.avatar.alt}
          objectFit="cover"
          w={{
            base: "80px",
            sm: "86px",
          }}
          h={{
            base: "80px",
            sm: "86px",
          }}
          className="lozad"
          borderRadius="100%"
        />
        <Flex gap="4px" flexDir="column">
          <Text
            textAlign={{
              base: "center",
              lg: "left",
            }}
            as="p"
            style={{ margin: "0" }}
          >
            {data.name}
          </Text>
          {data.job !== "" && (
            <Text
              as="p"
              style={{ margin: "0" }}
              fontSize="12px"
              color="#7D848E"
              textAlign={{
                base: "center",
                lg: "left",
              }}
            >
              {data.job}
            </Text>
          )}
        </Flex>
      </Flex>
      <Text textAlign="left" style={{ margin: "0" }} flex={1}>
        {data.comment}
      </Text>
    </Flex>
  );
};

const Comments = () => {
  return (
    <BlokContainer
      pt={{
        base: "32px",
        sm: "64px",
        md: "48px",
        lg: "56px",
        xl: "80px",
      }}
      pb={{
        base: "32px",
        sm: "64px",
        md: "48px",
        lg: "56px",
        xl: "80px",
      }}
      maxW="web_max_w"
      blockTitle={
        <Text as="h2" color="#fff" textAlign="center" m="0px">
          Trusted by Teams Big and Small with Top-tier Security
        </Text>
      }
      blockBg="#2A1445"
    >
      <Swiper
        className={styles.swiper_container}
        loop
        autoplay={{
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        }}
        spaceBetween={100}
        pagination={{
          clickable: true,
          bulletActiveClass: styles.swiper_bullet_active,
          bulletClass: styles.swiper_bullet,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {USER_COMMENTS.map((item, index) => {
          return (
            <SwiperSlide className={styles.swiper_slide} key={index}>
              <Flex
                w="full"
                p={{
                  base: "0 0 24px",
                  md: "0 24px 24px",
                }}
                flexDir="column"
                gap={{
                  base: "24px",
                  sm: "40px",
                }}
                bg="#2A1445"
              >
                <Grid
                  templateColumns={{
                    base: "1fr",
                    lg: "1fr 1fr",
                  }}
                  gap={{
                    base: "24px",
                    sm: "32px",
                    xl: "48px",
                    "2xl": "60px"
                  }}
                  h="full"
                >
                  <GridItem h="full">
                    <CommentCard data={item[0]} />
                  </GridItem>
                  <GridItem h="full">
                    <CommentCard data={item[1]} />
                  </GridItem>
                </Grid>
              </Flex>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </BlokContainer>
  );
};
export default Comments;
