import { Box, Center, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import React, { ReactComponentElement, ReactElement, ReactNode } from "react";
import { IFeatureItem, IPricingCard, PRICING_CARDS } from "../constants";
import * as styles from "./index.module.less";
import { PublicChecked } from "react-notticon";
import CommonButton from "@/components/common-button";
import Paragraph from "@/components/paragraph";

interface IProp {
  data: IPricingCard;
  gridArea: string;
  className?: string;
}

const CHECKED_ICON_COLOR = {
  Free: "#282B32",
  Plus: "#9F2AEC",
};

const CLASS_NAME = {
  Free: styles.free_card,
  Plus: styles.plus_card,
};

const START_BTN_STYLE = {
  Free: {
    color: "#9F2AEC",
    bg: "#FBF0FF",
    _hover: {
      bg: "#F3D1FF",
    },
    _active: {
      bg: "#E5A8FF",
      color: "#7C1AC7",
    },
  },
  Plus: {
    color: "#fff",
    bg: "#9F2AEC",
    _hover: {
      bg: "#7C1AC7",
    },
    _active: {
      bg: "#5C0DA1",
    },
  },
};

const BORDER_COLOR = {
  Free: "#ffffff",
  Plus: "#9F2AEC",
};

const MAX_W = {
  base: "460px",
  "2xl": "480px",
};

interface IFeatureItemProp {
  data: IFeatureItem;
  type?: "Free" | "Plus";
  isShowCheckIcon?: boolean;
  CustomIcon?: any;
}

export const FeatureItem = ({
  data,
  type = "Free",
  isShowCheckIcon = true,
  CustomIcon,
}: IFeatureItemProp) => {
  return (
    <Flex gap="8px">
      {isShowCheckIcon && (
        <Flex flexShrink="0" className={styles.check_icon_container}>
          {CustomIcon ? (
            <CustomIcon fill={CHECKED_ICON_COLOR[type]} />
          ) : (
            <PublicChecked fill={CHECKED_ICON_COLOR[type]} />
          )}
        </Flex>
      )}
      <Tooltip
        hasArrow
        label={data.tooltip?.content}
        p="12px"
        color="#FFFFFF"
        borderRadius="8px"
        bg="#1B1E28"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.1);"
        placement="bottom"
        closeOnClick={false}
      >
        <Box>
          <Text
            style={{ margin: "0" }}
            textDecorationLine={data.tooltip ? "underline" : "none"}
            textUnderlineOffset="6px"
            textDecorationColor="#ccc"
            textDecorationStyle="dashed"
            display="inline-block"
          >
            {data.name}
          </Text>
        </Box>
      </Tooltip>
    </Flex>
  );
};

/** planName & planDesc */
const CardHeader = ({ data, gridArea, className }: IProp) => {
  const { planName, planDesc, type } = data;
  return (
    <Flex
      maxW={MAX_W}
      flexDir="column"
      p={{
        base: "16px 16px 0",
        sm: "24px 16px 0",
        lg: "36px 24px 0",
      }}
      gap="8px"
      border={`2px solid ${BORDER_COLOR[type]}`}
      borderTopRadius="16px"
      borderBottom="none"
      gridArea={gridArea}
      className={`${styles.card_header} ${
        type === "Plus" && styles.card_header_plus
      } ${className}`}
      bg="#fff"
    >
      <Flex>
        <Box
          fontWeight="700"
          fontSize={{
            base: "22px",
            sm: "24px",
            lg: "26px",
            xl: "26px",
          }}
          lineHeight={{
            base: "30px",
            sm: "36px",
            lg: "38px",
            xl: "32px",
          }}
          w="full"
        >
          {planName}
        </Box>
        {type === "Plus" && (
          <Image
            src="https://a.storyblok.com/f/167495/141x36/f233c4c9db/tag-best-value.svg"
            alt="best value badge"
            w={{
              base: "76px",
              sm: "106px",
              xl: "140px",
            }}
            h={{
              base: "24px",
              sm: "30px",
              xl: "36px",
            }}
            objectFit="contain"
          />
        )}
      </Flex>
      <Paragraph color="#9DA3AB">{planDesc}</Paragraph>
    </Flex>
  );
};

/** Pricing Part */
const CardPricing = ({ data, gridArea, className }: IProp) => {
  const {
    currencySign,
    currencyUnit,
    planPricing,
    planTerm,
    pricingDesc,
    type,
  } = data;

  return (
    <Flex
      flexDir="column"
      gap="8px"
      maxW={MAX_W}
      p={{
        base: "24px 16px 0",
        lg: "24px 24px 0",
      }}
      border={`2px solid ${BORDER_COLOR[type]}`}
      borderY="none"
      gridArea={gridArea}
      bg="#fff"
      className={className}
    >
      <Flex alignItems="center" gap="12px">
        <Box>
          <Box
            fontWeight="700"
            fontSize={{
              base: "26px",
              sm: "28px",
              lg: "30px",
              xl: "32px",
            }}
            lineHeight={{
              base: "34px",
              sm: "36px",
              lg: "38px",
              xl: "40px",
            }}
          >
            {currencySign}
            {planPricing} {currencyUnit}
          </Box>
        </Box>
        {planTerm && (
          <Box>
            <Text style={{ margin: "0" }}>{planTerm}</Text>
          </Box>
        )}
      </Flex>
      <Paragraph className={styles.paragraph} color="#9DA3AB">
        {pricingDesc}
      </Paragraph>
    </Flex>
  );
};

/** plan 权限 */
const PlanPower = ({ data, gridArea, className }: IProp) => {
  const { planPower, type } = data;

  return (
    <Flex
      flexDir="column"
      gap="8px"
      maxW={MAX_W}
      p={{
        base: "24px 16px 0",
        lg: "24px 24px 0",
      }}
      border={`2px solid ${BORDER_COLOR[type]}`}
      borderY="none"
      gridArea={gridArea}
      bg="#fff"
      className={className}
    >
      {planPower.map((item, index) => {
        return (
          <FeatureItem
            data={item}
            type={type}
            key={index}
            CustomIcon={item.customIcon}
          />
        );
      })}
    </Flex>
  );
};

/** btn */
const StartBtn = ({ data, gridArea, className }: IProp) => {
  const { btn, type } = data;
  return (
    <Box
      p={{
        base: "24px 16px 0",
        lg: "24px 24px 0",
      }}
      border={`2px solid ${BORDER_COLOR[type]}`}
      borderY="none"
      maxW={MAX_W}
      w="full"
      gridArea={gridArea}
      bg="#fff"
      className={className}
    >
      <CommonButton
        gtmId={btn.gtmId}
        to={btn.link}
        content={btn.label}
        {...START_BTN_STYLE[type]}
      />
    </Box>
  );
};

/** Feature list */
const FeatureList = ({ data, gridArea, className }: IProp) => {
  const { featureTitle, featureGroup, type } = data;

  return (
    <Flex
      flexDir="column"
      gap={{
        base: "12px",
        xl: "16px",
        "2xl": "18px",
      }}
      maxW={MAX_W}
      p={{
        base: "24px 16px 36px",
        lg: "24px 24px 36px",
      }}
      border={`2px solid ${BORDER_COLOR[type]}`}
      borderTop="none"
      borderBottomRadius="16px"
      gridArea={gridArea}
      bg="#fff"
      className={className}
    >
      <Box fontWeight="700" fontSize="18px" lineHeight="26px">
        {featureTitle}
      </Box>
      <Flex
        gap={{
          base: "12px",
          xl: "16px",
          "2xl": "18px",
        }}
        flexDir="column"
      >
        {featureGroup.map((item) => {
          return <FeatureItem data={item} key={item.name} type={type} />;
        })}
      </Flex>
    </Flex>
  );
};

const CardGroup = () => {
  return (
    <Center
      className={styles.card_wrapper}
      w="full"
      // p={{
      //   base: "0 24px 64px",
      //   sm: "0 24px 80px",
      //   lg: "0 40px 96px",
      //   xl: "0 80px 120px",
      // }}
      bg="#F8F9FF"
    >
      <Box className={styles.card_main}>
        {PRICING_CARDS.map((item, index) => {
          return (
            <Box
              display={{
                base: "block",
                lg: "contents",
              }}
              mt={
                index !== 0 && {
                  base: "24px",
                  md: "32px",
                }
              }
              className={CLASS_NAME[item.type]}
            >
              <CardHeader data={item} key={item.type} gridArea={`a${index}`} />
              <CardPricing data={item} key={item.type} gridArea={`b${index}`} />
              <StartBtn data={item} key={item.type} gridArea={`c${index}`} />
              <PlanPower data={item} key={item.type} gridArea={`d${index}`} />
              <FeatureList data={item} key={item.type} gridArea={`e${index}`} />
            </Box>
          );
        })}
      </Box>
    </Center>
  );
};
export default CardGroup;
