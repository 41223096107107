import BlokContainer from "@/components/blok-container";
import Layout from "@/layout";
import Banner from "@/modules/pricing/banner";
import BottomCta from "@/modules/pricing/bottom-cta";
import CardGroup from "@/modules/pricing/card-group";
import Comments from "@/modules/pricing/comments";
import CompareTable from "@/modules/pricing/compare-table";
import Faqs from "@/modules/pricing/faqs";
import SecurityLogos from "@/modules/pricing/security";
import { Box, Center, Flex } from "@chakra-ui/react";
import Header from "@/components/head";
import React from "react";
import * as styles from "./index.module.less";
import AddOn from "@/modules/pricing/add-on";

export const Head = () => {
  return (
    <Header
      title="Airgram Pricing: Varied Plans to Satisfy Your Needs. Start for Free."
      description="Learn more about Airgram pricing plans for individuals and teams. Sign up now to enjoy productive meetings."
    />
  );
};

const Pricing = () => {
  return (
    <Layout
      isNewStyle
      px="0px"
      margin="0px"
      minW="100%"
      pt="0px"
      navMaxW="web_max_w"
    >
      <Center
        flexDir="column"
        bg="#F8F9FF"
        p={{
          base: "48px 24px",
          sm: "64px 40px",
          lg: "80px 48px",
          xl: "80px 120px",
          "2xl": "120px 160px",
        }}
        gap={{
          base: "24px",
          md: "32px",
          lg: "24px",
          xl: "48px",
        }}
      >
        <Flex
          flexDir="column"
          w="full"
          gap={{
            base: "40px",
            md: "48px",
            xl: "64px",
          }}
        >
          <Banner />
          <CardGroup />
        </Flex>
        <AddOn />
      </Center>
      <Box w="full" display={{ base: "none", md: "block" }}>
        <CompareTable />
      </Box>
      <Comments />
      <SecurityLogos />
      <BottomCta />
      <Faqs />
    </Layout>
  );
};
export default Pricing;
