import BlokContainer from "@/components/blok-container";
import { Box, Center, Flex, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FeatureItem } from "../card-group";
import {
  COMPARE_TABLE,
  ContentType,
  ICompareRowItem,
  IconType,
} from "../constants";
import * as styles from "./index.module.less";
import { PublicSubtract, PublicChecked } from "react-notticon";
import CommonButton from "@/components/common-button";
import { WEB_APP_SIGN_UP_LINK } from "@/utils/constant";

export enum planType {
  Free,
  Plus,
}

const ICON_COLOR = {
  [planType.Free]: "#1B1E28",
  [planType.Plus]: "#9F2AEC",
};

interface ICompareRowItemProp {
  data: ICompareRowItem;
}

const StartBtn = ({ to, label, gtmId, ...other }) => {
  return (
    <Link href={to} w="full" _hover={{ textDecor: "none" }} data-gtm={gtmId}>
      <Center {...other}>
        <Box
          style={{ margin: "0" }}
          fontWeight="700"
          fontSize={{ base: "16px" }}
          lineHeight={{ base: "22px" }}
        >
          {label}
        </Box>
      </Center>
    </Link>
  );
};

const FreeStartBtn = () => {
  return (
    <StartBtn
      gtmId="web_sign_up_cta_btn_pricing_free"
      to={WEB_APP_SIGN_UP_LINK}
      label="Get Started"
      p={{ base: "12px 8px" }}
      borderRadius="8px"
      color="#9F2AEC"
      bg="#FBF0FF"
      _hover={{
        bg: "#F3D1FF",
      }}
      _active={{
        bg: "#E5A8FF",
        color: "#7C1AC7",
      }}
    />
  );
};

const PlusStartBtn = () => {
  return (
    <StartBtn
      gtmId="web_sign_up_cta_btn_pricing_plus"
      to={WEB_APP_SIGN_UP_LINK}
      label="Get Started"
      p={{ base: "12px 8px" }}
      bg="#9F2AEC"
      color="#fff"
      borderRadius="8px"
      _hover={{
        bg: "#7C1AC7",
      }}
      _active={{
        bg: "#5C0DA1",
      }}
    />
  );
};

const renderCompareRowContent = (
  { type, content }: IconType | ContentType,
  planType: planType
) => {
  if (type === "icon") {
    if (content) {
      return <PublicChecked fill={ICON_COLOR[planType]} />;
    } else {
      return <PublicSubtract fill="#C0C6CB" />;
    }
  } else if (type === "string") {
    return (
      <Text textAlign="center" style={{ margin: "0" }}>
        {content}
      </Text>
    );
  }
  return "";
};

const CompareItemLayoutWrapper = ({
  col1 = <></>,
  col2 = <></>,
  col3 = <></>,
  className = "",
}) => {
  return (
    <Flex
      w="full"
      gap={{
        base: "32px",
        lg: "60px",
      }}
      alignItems="center"
      className={className}
    >
      <Box
        w={{
          base: "370px",
          md: "370px",
          lg: "480px",
        }}
      >
        {col1}
      </Box>
      <Flex
        flex="1"
        gap={{
          base: "24px",
          lg: "48px",
        }}
        alignSelf="stretch"
      >
        <Center h="full" flex={1} alignItems="stretch">
          {col2}
        </Center>
        <Center h="full" flex={1} alignItems="stretch">
          {col3}
        </Center>
      </Flex>
    </Flex>
  );
};

const CompareRowItem = ({ data }: ICompareRowItemProp) => {
  const { feature, free, plus } = data;

  return (
    <CompareItemLayoutWrapper
      className={styles.compare_row_item}
      col1={<FeatureItem data={feature} isShowCheckIcon={false} />}
      col2={
        <Center
          className={styles.compare_row_content}
          p={{
            base: "8px",
            lg: "12px",
          }}
          borderX="2px solid #DADEE3"
          w="full"
        >
          {renderCompareRowContent(free, planType.Free)}
        </Center>
      }
      col3={
        <Center
          className={styles.compare_row_content}
          p={{
            base: "8px",
            lg: "12px",
          }}
          borderX="2px solid #9F2AEC"
          w="full"
        >
          {renderCompareRowContent(plus, planType.Plus)}
        </Center>
      }
    />
  );
};

const CompareTable = () => {
  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const bottomBtnRef = useRef<HTMLDivElement>(null);
  const [isTableHeaderSticky, setIsTableHeaderSticky] = useState(false);

  const handleScroll = () => {
    const tableHeaderTop = tableHeaderRef.current?.getBoundingClientRect()?.top;
    const bottomBtnTop = bottomBtnRef.current?.getBoundingClientRect()?.top;
    const winH = window.innerHeight;

    if (tableHeaderTop <= 74) {
      setIsTableHeaderSticky(true);
    } else {
      setIsTableHeaderSticky(false);
    }

    if (bottomBtnTop < winH) {
      tableHeaderRef.current.style.transform = "translateY(-200%)";
    } else {
      tableHeaderRef.current.style.transform = "translateY(0px)";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Center
      w="full"
      flexDir="column"
      pb={{
        base: "80px",
        lg: "96px",
        xl: "120px",
      }}
    >
      {/* title */}
      <Center
        w="full"
        px={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "40px",
          xl: "80px",
          "2xl": "160px",
        }}
        mb={{
          base: "48px",
          lg: "64px",
        }}
        mt={{
          base: "80px",
          lg: "96px",
          xl: "120px",
        }}
      >
        <Text as="h2" maxW="web_max_w" my="0" textAlign="center">
          Compare Features Across Plans
        </Text>
      </Center>
      {/* table header */}
      <Center
        ref={tableHeaderRef}
        bg="#fff"
        pt="16px"
        px={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "40px",
          xl: "80px",
          "2xl": "160px",
        }}
        w="full"
        pos="sticky"
        top={{
          base: "70px",
          xl: "74px",
        }}
        boxShadow={
          isTableHeaderSticky ? "0px 4px 44px rgba(0, 0, 0, 0.25)" : "none"
        }
        transition="all 0.3s ease-in-out"
      >
        <CompareItemLayoutWrapper
          col2={
            <Center
              w="full"
              padding={
                isTableHeaderSticky
                  ? {
                      base: "12px 6px",
                      lg: "24px 12px",
                      // xl: "24px",
                    }
                  : {
                      base: "16px 8px",
                      xl: "24px 12px",
                    }
              }
              border="2px solid #DADEE3"
              borderBottom={isTableHeaderSticky ? "none" : "2px solid #DADEE3"}
              borderTopRadius="16px"
              bg="#fff"
              overflow="hidden"
              flexDir="column"
              gap="16px"
            >
              <Box
                fontWeight="700"
                fontSize={{
                  base: "22px",
                  sm: "24px",
                  lg: "26px",
                  xl: "28px",
                }}
                lineHeight={{
                  base: "30px",
                  sm: "36px",
                  lg: "38px",
                  xl: "40px",
                }}
              >
                Free
              </Box>
              {isTableHeaderSticky && <FreeStartBtn />}
            </Center>
          }
          col3={
            <Center
              w="full"
              padding={
                isTableHeaderSticky
                  ? {
                      base: "12px 6px",
                      lg: "24px 12px",
                      // xl: "24px",
                    }
                  : {
                      base: "16px 8px",
                      // xl: "24px 12px",
                    }
              }
              border="2px solid #9F2AEC"
              borderBottom={isTableHeaderSticky ? "none" : "2px solid #9F2AEC"}
              borderTopRadius="16px"
              className={styles.plus_deco_part}
              overflow="hidden"
              bg="#fff"
              flexDir="column"
              gap="16px"
            >
              <Box
                fontWeight="700"
                fontSize={{
                  base: "22px",
                  sm: "24px",
                  lg: "26px",
                  xl: "28px",
                }}
                lineHeight={{
                  base: "30px",
                  sm: "36px",
                  lg: "38px",
                  xl: "40px",
                }}
              >
                Plus
              </Box>
              {isTableHeaderSticky && <PlusStartBtn />}
            </Center>
          }
          className={styles.table_header}
        />
      </Center>
      {/* table body */}
      <Center
        w="full"
        px={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "40px",
          xl: "80px",
          "2xl": "160px",
        }}
      >
        <Flex
          flexDir="column"
          w="full"
          maxW={{
            base: "910px",
            "2xl": "1120px",
          }}
        >
          {COMPARE_TABLE.map((item, index) => {
            return (
              <Flex key={item.title} flexDir="column" w="full">
                <CompareItemLayoutWrapper
                  col1={
                    <Center
                      w="full"
                      p={{ base: "24px 0 8px", lg: "28px 0 12px" }}
                    >
                      <Box
                        w="full"
                        p="16px 16px"
                        bg="#F6F7F9"
                        borderRadius="16px"
                      >
                        <Text as="h4" my="0">
                          {item.title}
                        </Text>
                      </Box>
                    </Center>
                  }
                  col2={
                    <Center
                      w="full"
                      h="full"
                      borderX="2px solid #DADEE3"
                    ></Center>
                  }
                  col3={
                    <Center
                      w="full"
                      h="full"
                      borderX="2px solid #9F2AEC"
                    ></Center>
                  }
                />
                <Flex flexDir="column" w="full" justifyContent="center">
                  {item.featureList.map((item) => {
                    return (
                      <CompareRowItem data={item} key={item.feature.name} />
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}
          {/* table footer */}
          <CompareItemLayoutWrapper
            col2={
              <Center
                w="full"
                padding={{
                  base: "12px 6px",
                  lg: "24px 12px",
                  // xl: "24px",
                }}
                border="2px solid #DADEE3"
                borderBottomRadius="16px"
                bg="#fff"
                overflow="hidden"
                ref={bottomBtnRef}
              >
                <FreeStartBtn />
              </Center>
            }
            col3={
              <Center
                w="full"
                padding={{
                  base: "12px 6px",
                  lg: "24px 12px",
                  // xl: "24px",
                }}
                border="2px solid #9F2AEC"
                borderBottomRadius="16px"
                overflow="hidden"
                bg="#fff"
              >
                <PlusStartBtn />
              </Center>
            }
          />
        </Flex>
      </Center>
    </Center>
  );
};
export default CompareTable;
